textarea,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
    border-radius: 3px;
    -webkit-transition: .3s;
    transition: .3s;
}

label {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

input,
button,
select,
textarea {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
    background: #fff;
    border: 1px solid #dcd9d9;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 24px;
    border-radius: 0;
}

textarea {
    width: 100%;
    border: none;
    background: #f5f5f5;
    margin-bottom: 24px;
    padding: 16px 20px;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/* placeholder */
::-webkit-input-placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #d0d0d0;
    font-size: 14px;
}

:-moz-placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #d0d0d0;
    font-size: 14px;
}

::-moz-placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #d0d0d0;
    font-size: 14px;
}

:-ms-input-placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #d0d0d0;
    font-size: 14px;
}


#expert-subscription .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        margin-bottom: 0;
    }
}

@media (max-width: 480px) {
    #expert-subscription {
        .form-group {
            margin-bottom: 0;
        }
        
        .field-subscribermodel-captcha {
            transform: scale(0.75);
        }
    }
}