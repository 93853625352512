table {
    td,
    th {
        border: 1px solid #dcd9d9;
        padding: 8px 10px;
        color: #333333;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
    }
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
    float: none;
}
