body {
  counter-reset: num;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #424242;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.text-default {
  color: #1293d4;
}

.page-header {
  border-bottom: none;
}

#mainSearch input[type='checkbox'] {
  vertical-align: top;
  display: inline-block;
  margin-right: 6px;
  margin-top: 3px;
  cursor: pointer;
}

.sidebar-widget .title-saminormal a {
  text-transform: none;
}

.post-info {
  padding-top: 0;
}

.post-info .expert-name {
  color: #1293d4;
  font-size: 16px;
}

.t-comment {
  background: url(../../img/testimonial/t-shape.png) no-repeat left top/100% 99%;
}

.owl-carousel .owl-item img {
  width: 70px;
}

.tag-list a {
  border: none;
  margin-bottom: 0;
  margin-right: 0;
}

.tag-list a:hover {
  background: none;
  color: #1293d4;
}

.pagination .page-list li a,
.pagination .page-list li span {
  border-radius: 3px;
}

.search-form .search-form-btn {
  width: 95px;
  border-radius: 3px;
}

.search-form input,
.search-form button {
  height: 37px;
}

#main-block {
  overflow: hidden;
  padding-bottom: 20px;
  position: relative;
  flex-grow: 1;
}

.content-body {
  padding-top: 50px;
}

.pointer {
  cursor: pointer;
}

.dinblock {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
}

.sidebar-postlists .sidebear-post-thumb img {
  width: auto;
  height: 70px;
}

.sidebar-postlists .sidebar-postcontent .title-saminormal {
  margin-bottom: 5px;
}

.sidebar-postlists .sidebar-postcontent .anno {
  margin-top: 5px;
}

.card {
  background: #f4f4f4;
}

.avatar-round {
  border-radius: 50%;
}

.expert-container {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.expert-container .expert-name {
  font-size: 24px !important;
  margin-left: 10px;
}

.expert-container .expert-info p {
  color: #0e1a35;
  margin: 5px;
  font-size: 16px;
}

.expert-container .expert-info.specific {
  margin-left: 15px;
}

.expert-container .expert-info.specific .expert-name {
  font-size: 18px;
}

.expert-container h1.expert-name {
  margin: 5px;
  font-weight: 400;
  line-height: 1.2;
}

.location-map img {
  width: 100%;
}

.news-section {
  background: #f4f4f4;
  border-radius: 4px;
  padding-bottom: 10px;
}

.reviews-section {
  margin-bottom: 25px;
}

#article-page .reviews-section .blog-sidebar {
  padding: 15px 15px 0 !important;
}

.news-section .sidebar-postlists li {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
}

.news-list li {
  padding: 10px;
}

.news-list .item {
  background: #eee;
  height: 100%;
  overflow: hidden;
}

.item {
  overflow: hidden;
}

.item .wn-image img {
  width: 100%;
  height: auto;
}

ul.news-list {
  margin-top: -10px;
}

.news-list.list-inline li {
  width: 345px;
  vertical-align: top;
  margin-right: 10px;
  margin-left: 12px;
}

@media only screen and (max-width: 1196px) {
  .news-list.list-inline li {
    width: 277px;
  }
}

@media only screen and (max-width: 991px) {
  .news-list.list-inline li {
    width: 320px;
  }
}

@media only screen and (max-width: 768px) {
  .news-list.list-inline li {
    width: 370px;
  }
}

@media only screen and (max-width: 480px) {
  .news-list.list-inline li {
    width: 251px;
    margin-right: 0;
    margin-left: 0;
  }
}

#order-search-form label {
  font-size: 16px;
}

#expert-search-form {
  /*& .field-usersearchmodel-username {
          overflow: hidden;
      }*/
}

#expert-search-form .form-group {
  margin-bottom: 10px;
  overflow: hidden;
}

#expert-search-form .field-usersearchmodel-datefrom {
  padding-left: 0;
}

#expert-search-form .field-usersearchmodel-dateto {
  padding-right: 0;
}

#expert-search-form label {
  font-size: 16px;
}

#ui-datepicker-div {
  z-index: 10 !important;
}

#expert-page .news-list.list-inline li {
  width: 355px;
  vertical-align: top;
  margin-right: 10px;
  margin-left: 0;
}

@media only screen and (max-width: 1196px) {
  #expert-page .news-list.list-inline li {
    width: 287px;
  }
}

@media only screen and (max-width: 991px) {
  #expert-page .news-list.list-inline li {
    width: 317px;
  }
}

@media only screen and (max-width: 768px) {
  #expert-page .news-list.list-inline li {
    width: 370px;
  }
}

@media only screen and (max-width: 480px) {
  #expert-page .news-list.list-inline li {
    width: 251px;
    margin-right: 0;
    margin-left: 0;
  }
}

#expert-page .show-hide-btn {
  font-size: 16px;
}

.company-info p {
  font-size: 16px;
}

ul.tree,
ul.tree ul {
  list-style-type: none;
  background: url('/images/treeList/vline.png') repeat-y;
  margin: 0;
  padding: 0;
}

ul.tree ul {
  margin-left: 10px;
}

ul.tree li {
  margin: 0;
  padding: 0 12px;
  line-height: 20px;
  background: url('/images/treeList/node.png') no-repeat;
  color: #4f86fe;
  font-weight: bold;
}

ul.tree li a {
  color: #4f86fe !important;
}

ul.tree > div {
  text-align: center;
}

ul.tree ul.pagination {
  text-align: center;
  margin-top: 5px;
}

ul.tree ul.pagination li {
  background: none;
}

ul.tree .pagination > li > span {
  padding: 8px 12px;
}

ul.tree ul.pagination a {
  border: 1px solid #f1f1f1;
  display: inline-block;
  color: #343434 !important;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding: 8px 15px;
}

ul.tree ul.pagination a:hover,
ul.tree ul.pagination li.active a {
  color: #fff !important;
  background: #1293d4;
}

.bk-search input {
  width: 347px;
  border: 1px solid #c0c7d6;
  border-radius: 20px;
  background: url(../../img/icon/search-icon.png) no-repeat 14px 50%;
  padding: 6px 10px 6px 35px;
}

.expert-list .sidebear-post-thumb {
  float: left;
}

.expert-list .sidebar-postcontent {
  overflow: visible;
  text-align: left;
}

.modal-content-border {
  margin: 19px;
  border: 1px dotted #576993;
  border-radius: 8px;
}

.page-header {
  text-align: center;
  color: #304776;
  font-size: 36px;
}

.page-header .h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 60px;
}

.page-header p {
  color: #304776;
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
}

.news-slider {
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.news-slider:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  background: #dddee1;
  height: 1px;
}

#owl-news .item img,
#owl-lastnews .item img {
  display: block;
  width: 100%;
  height: auto;
}

.wn-image {
  width: 100% !important;
  height: 70px;
  overflow: hidden;
  margin-bottom: 10px;
}

.head-sl {
  color: #0e1a35;
}

.head-sl .h4 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 7px;
}

.top-nblock {
  color: #4b4b56;
  margin-bottom: 8px;
}

.ncat {
  color: #4b4b56;
  background: #dddee1;
  padding: 2px 13px 0;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 3px;
}

.ndiscription .h5 {
  color: #5284ff;
  font-size: 18px;
  font-weight: 700;
}

.descr-news {
  color: #8492af;
  display: flex;
}

.descr-news a {
  color: #8492af;
}

.descr-news .author-rimg {
  display: inline-block;
  width: 25%;
  padding: 0 10px;
}

.descr-news .author-rimg img {
  border-radius: 50%;
  width: 100%;
}

.descr-news p {
  width: 75%;
  padding-left: 10px;
  word-wrap: break-word;
}

.modal-content {
  padding: 15px;
  border-radius: 9px;
  position: relative;
}

.modal-border {
  border: 1px dotted #4e608d;
  border-radius: 8px;
  padding: 35px 20px 20px;
  text-align: center;
}

.mh-text p {
  font-weight: 700;
  color: #99aad1;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.mh-text .h3 {
  color: #3e6aca;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.m-wrapp {
  max-width: 330px;
  margin: 0 auto;
}

.modal-header {
  border-bottom: 0 !important;
  padding: 0 !important;
}

.modal-body label {
  color: #0e1a35;
  font-size: 15px;
  border-radius: 15px;
  margin-bottom: 5px !important;
  font-family: 'Roboto', sans-serif;
  padding-top: 0 !important;
  border: 0 !important;
}

.modal-body input {
  margin-bottom: 0;
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0 !important;
}

.modal-body .form-group {
  margin-bottom: 0;
}

.modal-body textarea {
  width: 100%;
  max-width: 100%;
  border-radius: 15px !important;
  height: 122px !important;
  border: 0 !important;
}

.modal-content {
  background: #f4f4f4 !important;
}

.modal-footer {
  border-top: 0 !important;
  text-align: center !important;
}

.modal-footer p {
  color: #a7a7a7;
}

.modal-footer .btn-primary {
  border-radius: 15px !important;
  width: 90%;
  margin-bottom: 15px;
}

.modal-body select {
  border-radius: 15px;
  border: 1px solid transparent;
}

.modal-dialog {
  max-width: 570px;
}

.rc-anchor-normal {
  width: 100% !important;
}

.modal-body {
  overflow: hidden;
}

.table-ratingwrap {
  overflow: auto;
  width: 100%;
}

.list-height {
  display: flex;
  flex-wrap: wrap;
}

.list-height li {
  width: 50% !important;
  background-clip: content-box;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0;
}

.list-height li .item {
  padding: 10px;
}

.list-height li article {
  padding: 10px;
}

.form-articleS {
  padding: 25px 20px;
  background: #cadaff;
  margin-bottom: 20px;
}

.form-articleS .h5 {
  color: #3e6aca;
  font-weight: 700;
  font-size: 24px;
}

.form-articleS label {
  color: #0e1a35 !important;
  font-size: 15px !important;
}

.form-articleS select,
.form-articleS input {
  border-radius: 18px !important;
  border: 0 solid #fff !important;
  margin-bottom: 15px !important;
}

.link-dashed,
.tag-cloud-word {
  position: relative;
  display: inline-block;
  color: #4f86fe !important;
  font-size: 18px;
}

.link-dashed:hover,
.tag-cloud-word:hover {
  color: #3e6aca !important;
}

.link-dashed:after {
  content: '';
  display: inline-block;
  background: url(../../img/bg/bg-dashed.png) repeat-x;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.mb20 {
  margin-bottom: 20px;
}

.read-morear {
  color: #4f86fe;
}

.read-morear:hover {
  color: #3e6aca;
}

.content-wrapper {
  overflow: hidden;
}

.prl-15 {
  padding: 15px 15px 0 !important;
}

.item-article {
  @media (max-width: 479px) {
    padding: 15px;
  }

  padding: 20px;
  background: #f4f4f4;
}

.post-img {
  margin-bottom: 10px;
  min-height: 70px;
}

.item-article .top-nblock {
  margin-bottom: 20px;
}

.item-article h1 {
  font-size: 24px;
  color: #5284ff;
}

.item-article h2 {
  font-size: 20px;
}

.item-article h3 {
  font-size: 18px;
}

.item-article {
  font-size: 16px;
  color: #49546c;
  margin-bottom: 20px;
}

.item-article p {
  text-indent: 30px;
  font-size: 16px;
  color: #49546c;
  line-height: 20px;
  word-wrap: break-word;
}

.item-article ul {
  padding-left: 30px;
  list-style-type: none;
}

.item-article ul li {
  padding-left: 15px;
  position: relative;
  margin-bottom: 15px;
}

.item-article ul li {
  list-style-type: none;
}

.item-article ul li:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #4f86fe;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 50%;
}

.item-article ol {
  list-style-type: none;
  counter-reset: num;
  padding-left: 0;
}

.item-article ol li {
  margin-bottom: 20px;
  text-indent: 30px;
}

.item-article ol li::before {
  content: counter(num) '. ';
  counter-increment: num;
  color: #4f86fe;
}

.comment-block {
  background: #cee5d4;
}

.content-wrapper .h3 {
  color: #097e2a;
  font-size: 24px;
}

.green-theme .h5 {
  color: #097e2a;
  font-size: 18px;
}

.list-borderb {
  border-bottom: 1px solid #097e2a;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

#comment-form .form-group label,
#comment-form .form-group input {
  display: inline-block;
  vertical-align: top;
  margin-right: -4px;
}

#comment-form .form-group label {
  width: 25%;
}

#comment-form .help-block {
  padding-left: 27%;
}

.comment-block {
  margin-bottom: 20px;
}

#comment-form label {
  white-space: nowrap;
  width: 140px;
  font-size: 15px;
  color: #0e1a35;
}

#comment-form input,
#comment-form textarea {
  border-radius: 16px;
  padding: 7px 20px;
  outline: none;
  color: #097e2a;
  width: 320px;
  max-width: 320px;
  margin-bottom: 10px;
}

#comment-form textarea {
  height: 120px;
}

#comment-form .field-commentmodel-recaptcha {
  margin-left: 25%;
  margin-bottom: 0;
}

.green-theme .btn-green {
  border: 0;
  margin-left: 25%;
  font-size: 18px;
}

.comment-wrapper {
  padding: 20px;
}

#rating-filter-header {
  background: #e9f1ec;
}

.expert-list {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  margin-right: -4px;
}

.expert-descr .expert-img {
  display: inline-block;
  vertical-align: middle;
  width: 40%;
  margin-right: -4px;
}

.expert-descr .expert-info {
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  margin-right: -4px;
}

.expert-descr .expert-img {
  text-align: center;
}

.expert-container {
  background: #f4f4f4;
}

.expert-name {
  font-size: 24px;
  overflow: hidden;
}

.article-numb {
  color: #4f86fe;
  text-decoration: underline;
}

.expert-info .fa {
  display: inline-block;
  width: 20px;
  text-align: center;
}

.title-exparticle {
  color: #5284ff;
  font-size: 18px;
  margin-bottom: 2px;
}

.item .n-date {
  margin-top: 4px;
  margin-bottom: 5px;
}

.n-date .comment-date {
  margin-left: 0;
}

.expert-list .text-primary a {
  color: #8492af;
}

.expert-list .news-list li:last-child {
  margin-bottom: 0;
}

.expert-list .news-list {
  margin-top: 10px;
}

.form-inpwrap {
  position: relative;
  padding-top: 30px;
}

.form-inpwrap .control-label {
  position: absolute;
  top: 5px;
  right: 0;
  transform: translateX(50%);
}

.form-articleS .form-group {
  margin-bottom: 0 !important;
}

.fbtn {
  font-size: 18px;
}

.form-articleS .btn-bradius {
  margin-left: 0;
}

#ratingmodal .m-wrapp {
  max-width: 100%;
}

.gt-ppe {
  background: #cee5d4;
}

.gt-ppe h3 {
  margin-bottom: 12px;
}

.gt-ppe input {
  outline: none;
  border: 0;
  border-radius: 20px;
}

.sidebar-postcontent {
  text-align: center;
}

.sidebar-postcontent img {
  width: 100%;
  height: auto;
}

.sidebar-postcontent .company-image {
  max-width: 150px;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.reviews {
  background: #f4f4f4;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 25px;

  .owl-carousel .owl-item img {
    width: 100%;
  }

  .reviews_text {
    margin-top: 30px;
  }

  .reviews_item {
    .wrapper {
      background-color: transparent;
      perspective: 1000px;
      background: #fff;
      padding: 15px;

      .reviews_item__inner {
        position: relative;
        width: 100%;
        height: 350px;
        transition: transform 1.3s;
        transform-style: preserve-3d;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        .reviews_text {
          opacity: 0;
        }

        .reviews_front-image {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:hover .reviews_item__inner {
        transform: rotateY(180deg);
      }

      .reviews_front-image {
        background: #fff;
        transition: all 1.3s ease;

        img {
          width: auto;
          max-height: 300px;
          margin: 0 auto;
        }

        .reviews_text {
          transition: opacity 1.3s ease;
        }
      }

      .reviews_back-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: rotateY(180deg);
        cursor: pointer;

        img {
          max-height: 100%;
        }

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .owl-nav {
    button {
      position: absolute;
      top: 40%;
      margin: 0;
      opacity: 1 !important;

      i {
        border-width: 0 2px 2px 0;
      }

      &:first-child {
        left: 0;

        i {
          transform: rotate(135deg);
        }
      }

      &:last-child {
        right: 0;

        i {
          transform: rotate(-45deg);
        }
      }

      &:hover {
        background: none !important;
      }
    }
  }
}

.nav-tabs {
  background: #dddee1;
}

.nav-tabs > li a {
  color: #777;
  padding-top: 4px !important;
}

.nav-tabs > li.active a {
  padding-top: 4px !important;
}

.nav-tabs > li.active a:before {
  content: '';
  height: 4px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #3e6aca;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #777;
  cursor: default;
  background-color: #f4f4f4 !important;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-tabs > li > a {
  padding: 4px 15px 0 !important;
}

.label-tabs {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  vertical-align: top;
}

.counter {
  display: inline-block;
  vertical-align: top;
  background: #3e6aca;
  color: #fff;
  border-radius: 10px;
  padding: 2px 3px 1px;
  font-size: 9px;
  margin-top: 3px;
  margin-left: 3px;
}

.btn-pull-left {
  float: left;
}

.list-tabs .item {
  margin-bottom: 30px;
}

.question-comment,
.ansver-comment {
  margin-bottom: 20px;
}

.question-comment .text-primary {
  max-width: 90%;
  min-width: 130px;
}

.ansver-comment .sidebear-post-thumb {
  width: 100%;
}

.question-comment span.comment-date,
.ansver-comment span.comment-date {
  color: #878797 !important;
  margin-top: 3px;
  display: inline-block;
  margin-left: 8px;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
}

.ansver-comment .text-primary {
  max-width: 90%;
  min-width: 130px;
}

.sideb-comment .ansver-comment .text-primary:after {
  content: '';
  border-bottom: 15px solid #fff;
  border-left: 0 solid transparent;
  border-right: 17px solid transparent;
  position: absolute;
  right: 70px;
  top: -15px;
  left: auto;
}

.sideb-comment .sidebear-post-thumb span.name {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #4b4b56;
}

.sideb-comment .sidebar-postcontent i {
  display: none;
}

.sideb-comment .sidebar-postcontent .comment-date {
  color: #878797;
  margin-top: 3px;
  display: inline-block;
  font-family: 'Roboto', sans-serif !important;
}

.sideb-comment li {
  width: 100% !important;
}

.sideb-comment .text-primary {
  background: #fff;
  border-radius: 8px;
  padding: 15px 18px;
  margin-top: 20px !important;
  color: #64646f;
  position: relative;
}

.sideb-comment .text-primary:after {
  content: '';
  border-bottom: 15px solid #fff;
  border-right: 0 solid transparent;
  border-left: 17px solid transparent;
  position: absolute;
  left: 30px;
  top: -15px;
}

.tab-content .list-height {
  background: transparent;
}

.rate-art div {
  float: left;
}

.empty {
  padding: 10px;
}

.form-control:focus {
  box-shadow: none !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../images/arrow.png) !important;
  background-position: 96% 50%;
  background-repeat: no-repeat;
}

.rate > span {
  display: inline-block;
  margin-top: 7px;
}

.addthis-link {
  text-align: center;
}

.addthis-links {
  margin-bottom: 15px;
}

.addthis_default_style .addthis_separator,
.addthis_default_style .at4-icon,
.addthis_default_style .at300b,
.addthis_default_style .at300bo,
.addthis_default_style .at300bs,
.addthis_default_style .at300m {
  float: none !important;
}

.mb0 {
  margin-bottom: 0;
}

.addthis {
  float: right;
  margin-top: -4px;
  margin-right: 7px;
}

.addthis a:hover {
  opacity: 0.7;
}

.add-link-article {
  text-align: right;
  margin-bottom: 15px;
  margin-top: -10px;
}

.expaddthis {
  margin-top: 8px;
  margin-right: 0;
  padding: 0 !important;
}

.listexp-photo {
  text-align: center;
}

.alert-error {
  background: rgba(172, 41, 37, 0.5);
}

.company-speciallist > li img {
  margin-right: 5px;
}

.company-speciallist > li {
  width: 100%;
}

.privacy-policy {
  padding: 20px;
  background: #f4f4f4;
}

.privacy-policy .content__title {
  margin-bottom: 20px;
}

.privacy-policy {
  @media (max-width: 480px) {
    padding: 15px;

    h3 {
      font-size: 21px;
    }
  }
}

.form-search input {
  width: 450px;
  border: 1px solid #c0c7d6;
  border-radius: 20px;
  background: url(../../img/icon/search-icon.png) no-repeat 14px 50%;
  padding: 6px 10px 7px 35px;
  margin-bottom: 0;
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: #c0c7d6;
  }
}

.bottom-block {
  margin-top: 10px;
  font-size: 13px;
}

.mt0 {
  margin-top: 0;
}

.divider {
  margin-top: 40px;
}

#servicemodal .modal-header span {
  font-size: 28px;
}

.at-icon-wrapper {
  border-radius: 50%;
}

.btn-pull {
  float: right;
  margin-right: 5px;
}

.modal-body table {
  width: 509px;
}

.modal-footer .btn-bradius {
  margin-left: 0;
}

#servicemodal .modal-body {
  overflow: auto !important;
}

#servicemodal .modal-body table {
  margin-bottom: 10px;
}

.helpblock-form {
  color: #a94442;
}

.sidebar-postcontent {
  overflow: hidden;
}

#owl-lastnews,
#owl-news {
  overflow: inherit;
}

#owl-lastnews .owl-height,
#owl-news .owl-height {
  overflow: hidden;
}

.category-descr ul {
  padding-left: 30px;
}

.category-descr ul li {
  padding-left: 15px;
  position: relative;
  margin-bottom: 15px;
}

.category-descr ul li:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #4f86fe;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: 50%;
}

.modal-body .form-articleS {
  background: transparent;
}

.btn-db {
  width: 100%;
}

.butt-exp {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.butt-exp .btn-bradius {
  margin-left: 0;
}

.link-expert-cont {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.link-expert-cont img {
  vertical-align: top;
}

.list-row {
  margin-left: -10px;
  margin-right: -10px;
}

.modal-body .text {
  margin-top: 30px;
  margin-bottom: 20px;
}

.tree-list {
  display: inline-block;
  margin-left: 10px;
}

.modal-body .text ul li {
  padding-left: 15px;
  position: relative;
  margin-bottom: 15px;
}

.modal-body .text ul li:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #4f86fe;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: 50%;
}

.modal-body .text ol {
  list-style-type: none;
  counter-reset: num;
  padding-left: 0;
}

.modal-body .text ol li {
  margin-bottom: 20px;
  text-indent: 30px;
}

.modal-body .text ol li::before {
  content: counter(num) '. ';
  counter-increment: num;
  color: #4f86fe;
}

.modal-body .text blockquote {
  border-left: 5px solid #ccc;
}

hr {
  border-top: 1px solid #ccc !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.modal-body table.kv-strength-container {
  width: 330px;
}

.item-article .list-tabs {
  padding-left: 0;
}

.item-article .list-tabs li {
  padding: 0;
  position: relative;
  margin-bottom: 0;
}

.item-article .list-tabs li:after {
  display: none;
}

.list-tabs > li > a {
  padding: 10px 15px !important;
}

.list-tabs > li.active a {
  padding-top: 10px !important;
}

.blockquote {
  font-style: italic;
}

.legilation-table {
  overflow: auto;
}

.list-frbuttons {
  margin-bottom: -1px;
}

.list-frbuttons > li > a {
  margin-right: 0 !important;
}

/* */
.post-img {
  position: relative;
}

.post-img-raters {
  position: absolute;
  padding: 5px;
  background-color: white;
  width: auto;
  font-size: 11px;
}

.post-img-rate {
  bottom: 5px;
  left: 5px;
}

.post-img-review {
  bottom: 5px;
  right: 5px;
}

/* */
.expertises ul li {
  float: left;
  font-size: 15px;
  margin: 5px;
}

.expertises ul li:after {
  top: 3px;
}

.main-ajax {
  padding-bottom: 0 !important;
  background: #f4f4f4 !important;
}

.main-ajax .container {
  padding: 0 !important;
}

.main-ajax .sidebar-widget {
  margin-bottom: 0 !important;
}

.main-ajax .content-ajax {
  width: 100% !important;
}

.main-ajax .form-articleS {
  background: #f4f4f4 !important;
}

.modal.in .modal-dialog {
  min-height: 80%;
}

.modal.in .modal-dialog iframe {
  height: 500px;
  width: 96%;
  border: 0;
  overflow: hidden;
}

.container p.text-primary {
  word-wrap: break-word;
}

.wn-image {
  position: relative;
}

.raters-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.45);
}

.post-img-raters {
  position: unset;
}

.wn-image .post-img-rate,
.wn-image .post-img-review {
  font-size: 14px;
  background: none;
  color: #e3e3e3;
}

.wn-image .post-img-rate,
.wn-image .post-img-review {
  display: inline-block;
}

.post-raters-wrapper {
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.post-raters-wrapper .post-img-review {
  margin-left: auto;
}

.description-news img {
  max-width: 100%;
  height: auto;
}

.description-news a {
  font-size: 16px;
}

.widget_expertises_block .widget_expertises_item {
  float: left;
  margin: 5px;
}

.widget_expertises_block .widget_expertises_item .widget_expertises_item_link {
  display: block;
  width: 100%;
  height: 100%;
  color: #f2f7fc;
  background-color: #2988c5;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
}

#preloader {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: url(../../images/preloader.gif) no-repeat center center;
}

#preloader-overlay {
  display: none;
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}

.text_categories p,
.text_categories ul {
  text-align: justify;
}

.main-page-ul,
.experts-ul,
.rating-ul,
.articles-ul {
  margin: 5px;
  padding-left: 40px;
}

.main-page-ul li,
.experts-ul li,
.rating-ul li,
.articles-ul li {
  list-style-type: unset;
}

.text_categories h1 {
  font-size: 34px;
}

.text_categories h2 {
  font-size: 28px;
}

.reglament {
  h3 {
    margin-bottom: 20px;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
  }

  &__item {
    display: flex;
    margin: 10px 0;
  }

  &__img {
    max-width: 50px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
  }
}

.company {
  &-primary {
    margin-top: 20px;
    padding: 20px 0;
    border: solid #c4c4c4;
    border-width: 2px 0 2px 0;
  }

  &-tabs {
    background-color: transparent;
    display: flex;
    justify-content: center;

    li {
      flex-grow: 1;
      padding: 20px;
      display: flex;
      justify-content: center;
      position: relative;
      border-bottom: 2px solid #c4c4c4;

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 4px;
        left: 0;
        bottom: -2px;
        background-color: #3e6aca;
      }

      a {
        padding: 0 !important;
        margin: 0;
        line-height: 1;
        border-radius: 0;
        border: none;
        color: #363636 !important;
        font-weight: 500;
        font-size: 16px;

        &:hover,
        &:active,
        &:focus {
          background-color: transparent !important;
          border: none;
        }
        &:before {
          display: none;
        }
      }

      &.active {
        &::after {
          width: 100%;
        }

        & > a {
          background-color: transparent !important;
          border: none;
          padding: 0 !important;

          &:hover,
          &:active,
          &:focus {
            background-color: transparent !important;
            border: none;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &-content {
    background-color: transparent;
    border-bottom: 2px solid #c4c4c4;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .dinblock {
      margin-bottom: 0;
    }
  }

  &-icon {
    color: #3e6aca;
    margin-right: 10px;

    span {
      font-size: 22px;
    }
  }

  &-description {
    padding: 20px 0;
  }

  .expertises-list {
    list-style: none;

    li {
      position: relative;
      margin: 10px 0;

      &:before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: #3e6aca;
        border-radius: 50%;
        left: -15px;
        top: 7px;
      }
    }
  }

  &-advantages {
    margin: 30px 0;
    background-color: #f4f4f4;
    padding: 25px;
  }

  &-consultation {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .consult-step {
      min-height: 150px;
      font-size: 15px;
      padding: 45px 9px 10px 9px;
      box-shadow: 0px 1px 5px rgba(153, 153, 153, 0.54);
      border-radius: 6px;
      text-align: center;
      position: relative;
      line-height: 1.5;
      flex-basis: 160px;

      &__item {
        &-head {
          background: #3e6aca;
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px 0;
          width: 100%;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          text-transform: uppercase;
          color: #fff;
          font-size: 12px;
          line-height: 14px;
        }

        &-text {
          margin-top: 12px;
          span {
            font-weight: 500;
            line-height: 16px;
          }

          .short-code-replacer-hr {
            button {
              background: #097e2a;
              border-radius: 100px;
              padding: 3px 7px;
              text-transform: uppercase;
              color: #fff;
              font-size: 11px;
            }

            &::before {
              background: transparent;
            }
          }
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        right: -15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6.5px 0 6.5px 15px;
        border-color: transparent transparent transparent #3e6aca;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

.advantages {
  &_head {
    text-align: center;

    .h4 {
      font-weight: 500;
      font-size: 18px;
      color: #3e6aca;
    }
  }

  &_col {
    margin-top: 10px 0;
  }

  &_item {
    background-color: #fff;
    margin: 15px 0;
    display: flex;
    align-items: center;
    padding: 20px 15px;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &_icon {
    width: 35px;
    min-width: 35px;
    margin-right: 15px;

    @media (max-width: 480px) {
      margin: 0 0 15px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &_desc {
    font-size: 14px;
    line-height: 1.3;
  }
}

// modal
.shows {
  z-index: 999;
  display: none;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.66);
    position: fixed;
    top: 0;
    left: 0;
  }

  .img-show {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: fit-content;

    span {
      position: absolute;
      top: 20px;
      right: 35px;
      z-index: 99;
      cursor: pointer;

      &:before,
      &::after {
        content: '';
        width: 25px;
        height: 4px;
        background: #c7c7c7;
        display: block;
        border-radius: 1px;
        position: absolute;
        left: 0;
        top: 50%;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    img {
      width: auto;
      max-height: 90vh;
    }
  }
}

.breadcrumb {
  @media (max-width: 991px) {
    overflow: auto;
    display: flex;

    > li {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  > .active > a {
    color: #777;

    &:hover {
      text-decoration: none;
    }
  }
}

.pagination > ul {
  padding: 0;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

#top-banner-wrapper {
  text-align: center;
}
#topBannerButton {
  display: inline-block;
}
#top-banner-img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 120px;
}
#top-banner-wrapper hr {
  margin: 0.5rem 0;
}

.expertises_block {
  display: flex;
  flex-direction: column;

  span {
    font-size: 18px;
    font-weight: 700;
  }
}