h1, h2, h3, h4, h5, h6 {
    text-transform: none;
    margin: 0 0 10px;
    font-weight: 700;

    a {
        color: inherit;
        font-weight: inherit;

        &:hover {
            text-decoration: none;
            color: #1293d4;
        }
    }
}

a {
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-decoration: none;
    color: #1293d4;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    text-transform: none;

    &:focus {
        text-decoration: none;
    }

    &:active,
    &:hover {
        outline: 0 none;
    }

    &:hover {
        text-decoration: none;
        color: #1293d4;
    }
}

h2 {
    font-size: 28px
}

p {
    margin: 0 0 15px;
    font-family: 'Roboto', sans-serif;
    color: #424242;
    font-size: 14px;
    line-height: 1.2em;
}

hr {
    margin: 60px 0;
    padding: 0px;
    border-bottom: 1px solid #eceff8;
    border-top: 0px;
}
