.mb5 {
    margin-bottom: 5px !important;
}

.mb-3 {
    margin-bottom: 3rem;
}

.pt-30 {
    padding-top: 30px
}

.mt-10 {
    margin-top: 10px
}

.mt-1 {
    margin-top: 1rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-40 {
    margin-top: 40px
}

.mt-100 {
    margin-top: 100px;
}

.mb-10 {
    margin-bottom: 10px
}

.mb-20 {
    margin-bottom: 20px
}

.ml-10 {
    margin-left: 10px
}

.p-1 {
    padding: 1rem;
}
.pt-1 {
    padding-top: 1rem;
}

.pr-3 {
    padding-right: 3rem;
}

.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.fullscreen {
    height: 100vh;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
}

.hide-text {
    margin-left: 4px;

    @media (max-width: 767px) {
        display: none;
    }

}
