.partnership {
  &_row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &_item {
    min-width: 260px;
    &:first-child {
      width: 60%;
    }

    &:last-child {
      width: 40%;
      padding: 20px 15px;
    }

    @media (max-width: 768px) {
      &:first-child,
      &:last-child {
        width: 100%;
      }
      &:first-child {
        order: 1;
      }
    }
  }

  &_form {
    background-color: #f4f4f4;
    padding: 20px;
    margin: 20px 0;

    .form-group {
      margin-bottom: 0;
    }

    form {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    input,
    textarea {
      box-sizing: border-box;
      margin-bottom: 0;
      width: 100%;
    }

    .field-partnershipform {
      &-name,
      &-company,
      &-email,
      &-phone {
        width: calc(50% - 30px);
        margin-right: 15px;
        margin-left: 15px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &-activity,
      &-comment {
        width: 100%;
        margin-right: 15px;
        margin-left: 15px;
      }
    }

    .field-companyadminmodel-recaptcha {
      margin-top: 25px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 375px) {
        .g-recaptcha {
          transform: scale(0.8);
        }
      }
    }

    .btn_partnership {
      border: none;
      padding: 10px 25px;
      margin-left: 0;
    }

    .partnership_anotation {
      margin: 20px 0;

      p {
        margin: 0;
      }
    }

    .wrapper {
      display: flex;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      > .col {
        margin: 0;
        width: 100%;

        @media screen and (min-width: 768px) {
          width: calc(50% - 30px);
          margin: 0 15px;
        }
      }
    }
  }
}
