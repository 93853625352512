a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a,
input {
  outline: medium none;
  color: #1293d4;
}

big, .big {
  font-size: 110%;
  line-height: 180%;
}

.subtitle {
  color: #fff;
  font-weight: 400;
  font-size: 36px;
}

.subtitle a:hover {
  color: #1293d4;
}

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 22px;
  color: #333333;
}

h3 a:hover,
.title a:hover {
  color: #1293d4;
}

.semi-title,
.semi-title a {
  font-weight: 400;
  color: #333333;
  font-size: 18px;
  line-height: 24px;
}

h4 a:hover,
.semi-title a:hover {
  color: #1293d4;
}

.title-saminormal {
  color: #333333;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.title-saminormal a:hover {
  color: #1293d4;
}

.google-map img {
  max-width: none !important;
}

.transparent {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.transparent:hover,
.transparent:focus,
.transparent:active {
  border-color: #1293d4;
}

/* info boxy style 1 */
.info-box-desc p {
  margin-bottom: 0;
}

.info-box span, .info-box span i {
  display: inline-block;
}

/* info box style 5 */
.info-box-style-5 {
  padding: 0 25px;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.info-box-top {
  margin-bottom: 15px;
}

.info-box-style-5 span {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #1293d4;
  border-radius: 0 20px;
  color: #333333;
  font-size: 30px;
  display: block;
  height: 70px;
  line-height: 70px;
  margin: auto;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 70px;
}

.info-box-style-5:hover .info-box-top span {
  background: #1293d4;
  color: #fff;
  cursor: pointer;
}

.text-center .img-responsive,
.text-center.img-responsive {
  margin: auto;
}

.input-number-no-spin {
  -moz-appearance: textfield;
}
.input-number-no-spin::-webkit-inner-spin-button {
  display: none;
}

.short-code-replacer-hr {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  position: relative;
}

.short-code-replacer-hr:before {
  content: '';
  width: 100%;
  height: 2px;
  left: 0;
  top: calc(50% - 1px);
  z-index: 1;
  position: absolute;
  background-color: #3e6aca;
}

.button_wrap {
  padding: 0 15px;
  background-color: #f4f4f4;
  z-index: 2;
}

.consult .modal-header {
  text-align: center;
}

.phone-tooltip {
  font-size: 13px;
}