.services-single-page {
    margin-bottom: 20px;

    .services-banner {
        margin-bottom: 20px;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.services-result {
    margin: 20px 0;
    list-style-type: none;
    padding: 0;

    li {
        background: #eee;
        padding: 10px;
        margin-bottom: 10px;
    }

    .hilite {
        color: #1293d4;
    }
}

.table-scrollwrapper {
    margin: 20px 0;
}

.table-slick-header {
    height: 600px;
    overflow-x: auto;
    overflow-y: auto;

    thead {
        background: #eee;
    }

    .fancyTable td,
    .fancyTable th {
        border: 1px solid #789;
        padding: 5px;
        font-size: 12px;
        color: #121517;
    }

    .fancyTable thead,
    .fancyTable tfoot {
        tr th,
        tr td {
            font-weight: bold;
        }
    }

    .fancyTable tbody tr td {
        /* appearance */
        background-color: #eef2f9;
        font-size: 11px;
    }

    .url {
        cursor: pointer;
    }

    .url:hover td {
        color: #000;
        background: #fff;
    }
}
