// tooltip
.tooltip-inner {
    background-color: #1293d4;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #1293d4;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #1293d4;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #1293d4;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #1293d4;
}

// iframe
iframe {
    max-width: 100%;
}

// img
img {
    max-width: 100%;
    height: auto;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.mark, mark {
    background: #4fc1f0;
    color: #ffffff;
}

em, i {
    font-style: italic;
}

b, strong {
    font-weight: 700;
}

dt {
    font-weight: 700;
}

span {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.uppercase {
    text-transform: uppercase;
}

.clear {
    clear: both
}

.fix {
    overflow: hidden
}
