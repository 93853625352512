// table on home page
.company-table {
  th,
  td {
    height: 88px;
    border: 1px solid #fff;
    font-family: 'Roboto', sans-serif;
    vertical-align: middle !important;
  }
}

.rating-tablegen {
  position: relative;
  background: linear-gradient(#cce4d3 0%, #dce7fe 100%);

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0;
    bottom: 0;
    height: 4px;
    display: inline-block;
    background: #4f86fe;
  }

  thead {
    background: #097e2a;
  }

  th {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  td {
    font-weight: 500;
    border: 1px solid #fff !important;
    text-align: left;

    img {
      width: 50px;
      height: 50px;
      margin-right: 5px;
    }

    a {
      text-decoration: underline;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }

    a,
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.table-leftcol {
  display: inline-block;
  margin-right: -4px;
  width: 245px;
  max-width: 245px;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 200px;
    max-width: 200px;
  }

  @media (max-width: 767px) {
    width: 100px;
    max-width: 100px;
  }

  .rating-tablegen,
  .rating-tablegen td {
    width: 245px;
    max-width: 245px;

    @media (max-width: 1200px) {
      width: 200px;
      max-width: 200px;
    }

    @media (max-width: 767px) {
      width: 100px;
      max-width: 100px;
    }
  }

  .company-name {
    display: block;
  }
}

.table-rightcol {
  width: calc(100% - 245px);
  overflow: hidden;
  display: inline-block;
  margin-right: -4px;

  @media (max-width: 1200px) {
    width: calc(100% - 200px);
  }

  @media (max-width: 767px) {
    overflow: auto;
    width: calc(100% - 100px);
  }

  .rating-tablegen {
    th,
    td {
      text-align: center;
    }

    td {
      width: 114px;
      max-width: 125px;

      @media (max-width: 1200px) {
        width: 75px;
        max-width: 91px;
      }

      @media (max-width: 767px) {
        width: 108px;
        max-width: 100px;
      }

      span {
        display: inline-block;
        width: 114px;
        max-width: 125px;
        vertical-align: middle;

        @media (max-width: 1200px) {
          width: 75px;
          max-width: 91px;
        }
      }
    }

    thead th:nth-child(odd) {
      background: rgba(14, 26, 53, 0.26);
    }

    tbody td:nth-child(odd) {
      background: rgba(44, 119, 73, 0.1);
    }
  }
}

// table on company page
.ratingp-table {
  min-width: 940px;

  th,
  tr {
    vertical-align: middle !important;
  }

  td a {
    text-decoration: underline;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  th {
    a {
      color: #fff;
      text-transform: uppercase;
    }
  }

  input,
  select {
    margin-bottom: 0 !important;
    border-radius: 18px !important;
    border: 1px solid #ccc;
  }

  thead {
    th:nth-child(even) {
      background: rgba(14, 26, 53, 0.26);
    }
  }

  tbody {
    td {
      color: #0e1a35;
      max-width: 394px !important;

      &:first-child {
        text-align: left;
        white-space: nowrap;

        img {
          display: inline-block;
          width: 50px;
          min-height: 50px;
        }

        a {
          display: inline-block;
          width: calc(100% - 50px);
          vertical-align: middle;
        }
      }

      &:nth-child(even) {
        background: rgba(44, 119, 73, 0.1);
      }

      &.td-trancate span {
        white-space: normal !important;
        font-weight: 500 !important;
      }
    }
  }
}
