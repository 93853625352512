button {
    outline: medium none;
    color: #1293d4;
}

.button-primary {
    background: #1293d4;
    border-radius: 6px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 0 25px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    border: none;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
    }
}

.button-primary > i,
.button-primary span {
    font-size: 20px;
    padding-left: 5px;
    position: relative;
    top: 2px;
}

.button-sm {
    font-size: 12px;
    padding: 0 10px;
    line-height: 33px;
    text-transform: capitalize;
}

.button-primary.button-sm > i,
.button-primary.button-sm span {
    font-size: 16px;
    padding-left: 5px;
}

.btn-bradius {
    border-radius: 20px !important;
    min-width: 100px;
    text-align: center;
    background: #3e6aca !important;
    border-color: #3e6aca;

    &:hover {
        background: #82a9fc !important;
        color: #fff;
        border-color: #82a9fc;
    }
}

.btn-green,
.btn-blue,
.btn-orange {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    border-radius: 20px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 15px;
    margin-top: 20px;
    white-space: nowrap;

    .icon {
        font-size: 12px;
    }

    &:hover {
        text-decoration: none;
        color: #fff;
    }

    &:focus {
        color: #fff;
    }
}

.btn-green {
    background: #097e2a;
    float: left;

    &:hover {
        background: #20c34e;
    }
}

.btn-green[disabled]:hover {
    background: #097e2a;
}

.btn-blue {
    background: #3f66ba;
    margin-left: 8px;

    &:hover {
        background: #82a9fc;
    }
}

.btn-orange {
    background: #ed8b39;
    margin-left: 12px;

    &:hover {
        background: #eeac76;

        span {
            color: #fff;
        }
    }
}

.btn-npr {
    color: #1c263e;
    font-weight: 700;
    border-radius: 19px;
    border: 1px solid #d2d6df;
    padding: 7px 12px 5px;;
}

.btn-closex {
    position: absolute;
    top: 21px;
    right: 21px;
    width: 25px;
    height: 25px;
    opacity: 1 !important;
    z-index: 200;

    &:hover {
        opacity: 0.7 !important;
    }

    span {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        &:before,
        &:after {
            content: "";
            width: 25px;
            height: 4px;
            background: #c7c7c7;
            display: block;
            border-radius: 1px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}
