header {
    position: relative;
}

.header-top {
    padding: 2rem 0;
    background: #fff;
    color: #424242;

    .header-top-layout {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1200px) {
            flex-wrap: wrap;
            flex-direction: column;
        }

        a,
        span {
            color: #fefefe;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 13px;
            text-transform: capitalize;
        }

        .header-top-logo {
            width: 195px;
            order: 0;
            max-width: 200px;
            min-height: 58px;
        }

        .header-top-search {
            order: 2;

            @media (max-width: 1200px) {
                order: 1;
                margin: 1rem;
            }

            .form-group {
                margin-bottom: 0;

                input[type = "mainSearch"] {
                    width: 350px;
                    border: 1px solid #c0c7d6;
                    border-radius: 20px;
                    background: url(../../img/icon/search-icon.png) no-repeat 14px 50%;
                    padding: 6px 10px 6px 35px;
                    margin-right: 1rem;

                    @media (max-width: 767px) {
                        width: 100%;
                        margin: 0 0 1rem 0;
                    }
                }

                .btn-bradius {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                label {
                    color: #273b65;
                    padding: 0 10px;
                    cursor: pointer;

                    @media (max-width: 767px) {
                        padding: 0 2px;
                    }
                }
            }
        }

        .header-top-socials {
            order: 1;
            display: flex;
            align-items: center;

            .btn-orange {
                display: flex;
                align-items: center;
                margin: 0 4px;
                min-height: 36px;
                min-width: 36px;

                span {
                    text-transform: uppercase;
                    transition: all 0.3s ease 0s;
                    font-size: 14px;
                }
            }

            .top-socials {
                display: flex;
                align-self: center;

                a.socials {
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 36px;
                    width: 36px;
                    margin: 0 4px;

                    &-fb {
                        background-color: rgb(59, 89, 152);
                    }

                    &-vk {
                        background-color: rgb(99, 131, 168);
                    }

                    &-instagram {
                        background-color: #C536A4;
                    }

                    &-youtube {
                        background-color: rgb(223, 23, 23);
                    }

                    &-telegram {
                        background: url("/img/social/telegram.png") no-repeat center;
                        background-size: contain;
                    }

                    &:hover {
                        opacity: 0.7;
                    }

                    span {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.header-bottom {
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    position: relative;
    z-index: 5;
    background: #4f86fe;

    .header-bottom-layout {
        .main-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            @media (max-width: 1200px) {
                padding: 20px 0;
            }

            #mobile-btn {
                @media (min-width: 1200px) {
                    display: none;
                }

                height: 42px;
                width: 42px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #3e6aca;
                text-align: center;
                position: relative;

                &:before,
                &:after,
                span.lines:before,
                span.lines:after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 2px;
                    background: #fff;
                    display: inline-block;
                }

                &:before {
                    top: 11px;
                    left: 12px;
                }

                &:after {
                    bottom: 11px;
                    left: 12px;
                }

                span.lines {
                    &:before,
                    &:after {
                        width: 26px;
                    }

                    &:before {
                        top: 17px;
                        left: 8px;
                    }

                    &:after {
                        bottom: 17px;
                        left: 8px;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

            }

            .main-nav-action {
                order: 1;

                #btn-feedback {
                    margin: 0;
                }

                #btn-rating {
                    margin: 0 0 0 2rem;
                }
            }

            .main-nav-menu {
                order: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-grow: 1;

                @media (max-width: 1200px) {
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                }
            }
        }

        .main-nav-menu {
            .menu-list {

                @media (max-width: 1200px) {
                    width: 100%;
                    background-color: #4f86fe;
                }

                .nav {
                    display: flex;

                    @media (max-width: 1200px) {
                        flex-direction: column;
                    }

                    li {
                        @media (max-width: 1200px) {
                            border-top: 1px solid #c8c8c8;
                        }
                    }

                    a {
                        display: flex;
                        position: relative;
                        color: #fff;
                        padding: 20px 14px;
                        font-size: 18px;
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 100%;
                        width: 100%;

                        &:hover,
                        &:focus {
                            background-color: transparent;
                        }

                        @media (max-width: 1200px) {
                            text-transform: uppercase;
                            text-align: left;
                            padding: 14px;

                            &:hover,
                            &:focus {
                                background: hsla(0,0%,100%,.1);
                            }
                        }
                    }

                    .dropdown {
                        .dropdown-toggle {
                            .caret {
                                display: none;

                                @media (max-width: 1200px) {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: transparent;
                                    height: 100%;
                                    width: 50px;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    right: 0;
                                    border: none;
                                    border-left: 1px solid #c8c8c8;

                                    &:before {
                                        content: "+";
                                        font-weight: bold;
                                        font-size: 22px;
                                    }

                                    &.opened:before {
                                        content: "-";
                                    }
                                }
                            }
                        }

                        .dropdown-menu {
                            background-color: #3e6aca;
                            padding: 0;
                            margin: 0;
                            min-width: 250px;
                            max-width: 300px;
                            overflow-x: hidden;
                            overflow-y: auto;
                            max-height: 50vh;

                            @media (max-width: 1200px) {
                                position: static;
                                background-color: #1293d4;
                                border-radius: 0;
                                box-shadow: none;
                                border: none;
                                max-height: none;
                                max-width: 100%;
                                width: 100%;
                            }

                            li {

                                a {
                                    width: 100%;
                                    padding: 0.6rem 1rem;
                                    font-size: 14px;

                                    &:hover {
                                        padding-left: 2rem;
                                    }

                                    @media (max-width: 1200px) {
                                        padding: 1.4rem 4rem;
                                    }
                                }
                            }
                        }

                        &:hover .dropdown-menu {
                            display: block;
                        }

                        @media (max-width: 1200px) {
                            &:hover .dropdown-menu {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.language {
    display: inline-block;

    .dropdown-menu {
        margin-top: 15px;
    }
}
