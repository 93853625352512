.faq-page {
    .faq-page-layout {

        h1 {
            font-size: 24px;
            color: #5284ff;
        }

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 18px;
        }

        p {
            text-indent: 30px;
            font-size: 16px;
            line-height: 20px;
            word-wrap: break-word;
        }

        ul {
            padding-left: 30px;
            list-style-type: none;

            li {
                padding-left: 15px;
                position: relative;
                margin-bottom: 15px;

                &:after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    background: #4f86fe;
                    display: inline-block;
                    position: absolute;
                    top: 7px;
                    left: 0;
                    border-radius: 50%;
                }
            }
        }

        ol {
            list-style-type: none;
            counter-reset: num;
            padding-left: 0;

            > li {
                margin-bottom: 20px;
                text-indent: 30px;

                &::before {
                    content: counter(num) '. ';
                    counter-increment: num;
                    color: #4f86fe;
                }
            }
        }
    }
}
