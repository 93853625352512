footer.footer {
    background: #0e1a35;
    color: #fff;

    .footer-layout {
        display: flex;
        align-items: flex-start;
        padding: 30px 0;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        .layout-item {
            text-align: left;

            @media screen and (max-width: 1200px) {
                width: 50%;
            }

            @media screen and (max-width: 768px) {
                text-align: center;
                width: 100%;
            }
        }

        .footer-copyright {
            @media screen and (max-width: 768px) {
                order: 1;
            }

            p {
                color: #8492af;
                font-size: 18px;
            }

            .footer-logo {
                display: inline-block;
            }
        }

        .footer-widget {
            .footer-nav {
                display: flex;
                list-style: none;
                flex-wrap: wrap;

                &.footer-menu {
                    @media screen and (max-width: 768px) {
                        justify-content: center;
                        order: 1;
                    }
                }

                &.link-privacy-policy {
                    li {
                        width: 100%;
                    }
                }

                a {
                    font-size: 18px;
                    display: block;
                    padding-bottom: 8px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 0 5px;
                    color: #8492af;

                    &:hover {
                        color: #1293d4;
                    }
                }

                @media screen and (max-width: 768px) {
                    padding: 0;
                }
            }
        }
    }
}

#scrollUp {
    bottom: 20px;
    right: 20px;
    font-size: 30px;
    position: fixed;
    z-index: 2147483647;
    display: none;

    span {
        background-color: #fafafa;
        border: 1px solid #1293d4;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        display: inline-block;
        color: #1293d4;
        font-size: 32px;
        height: 50px;
        line-height: 52px;
        position: relative;
        text-align: center;
        -webkit-transition: all .3s ease 0s;
        -o-transition: all .3s ease 0s;
        transition: all .3s ease 0s;
        width: 50px;
        z-index: 9999;

        &:after,
        &:before {
            -webkit-border-radius: 50%;
            border-radius: 50%;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            -webkit-transition: all .1s ease 0s;
            -o-transition: all .1s ease 0s;
            transition: all .1s ease 0s;
            width: 100%;
            z-index: -1
        }
    }

    &:hover {
        span {
            background-color: #1293d4;
            color: #fff;
            border-color: transparent;
            cursor: pointer;
            position: relative;
            -webkit-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);

            &:after {
                -webkit-transform: translateY(5px);
                -ms-transform: translateY(5px);
                transform: translateY(5px);
                border-bottom: 3px solid #1293d4;
            }

            &:before {
                border-bottom: 2px solid #fff;
                -webkit-transform: translateY(2px);
                -ms-transform: translateY(2px);
                transform: translateY(2px);
            }
        }

        .arrow {
            border-color: #fff;
        }
    }
}
