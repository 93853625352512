@media (max-width: 1200px) {
  .articles-news,
  .article-news {
    display: none;
  }

  #experts-page .news-section {
    display: none;
  }

  #experts-page .pr-3 {
    padding-right: 15px;
  }

  .expert-list {
    width: 100%;
  }

  #company-page .expert-list {
    width: 50%;
  }

  .list-height li {
    width: 100% !important;
  }

  .list-tabs li {
    width: 50% !important;
  }

  .list-frbuttons li {
    width: 33.332% !important;
  }

  .btn-blue {
    margin-left: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1169px) {
  h3,
  .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 22px;
  }

  .h4,
  .semi-title,
  .semi-title a,
  h4,
  .semi-title,
  .semi-title a {
    font-size: 17px;
  }

  .articles-news {
    display: none;
  }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    width: 750px;
  }

  nav li .parent-angle {
    display: none;
  }

  /* Slider */
  h1 {
    font-size: 45px;
  }

  .subtitle {
    font-size: 30px;
  }

  .services-list .info-box {
    margin-bottom: 50px;
  }

  /* blog */
  .post-img img {
    width: 100%;
  }

  .mob-mt-50 {
    margin-top: 50px;
  }

  .about-theme {
    text-align: left;
  }

  .about-img img {
    width: 100%;
  }

  .blog-sidebar {
    margin-top: 50px;
  }

  .info-box-style-5 {
    margin-bottom: 40px;
  }

  div[class*='col-']:nth-last-child(1) .info-box-style-5,
  div[class*='col-']:nth-last-child(2) .info-box-style-5 {
    margin-bottom: 0;
  }

  /*----------------------------------------*/
  /*  Shortcodes
    /*----------------------------------------*/
  .breadcrumb-item a,
  .breadcrumb-item.active {
    font-size: 20px;
  }

  .breadcrumb > li + li:before {
    font-size: 20px;
  }

  .img-thumbnail {
    padding: 0;
    border: none;
  }
}

@media (max-width: 991px) {
  .listexp-photo {
    text-align: left;
  }

  .news-list li {
    width: 100% !important;
  }

  .articles-news {
    display: none;
  }

  .list-height li {
    width: 100% !important;
    padding: 10px 15px !important;
  }

  .list-row li {
    padding: 10px !important;
  }

  .expaddthis {
    text-align: left;
  }

  .article-news {
    display: none;
  }

  #experts-page .pr-3 {
    padding-right: 15px;
  }

  .list-tabs .item {
    padding-right: 0;
    padding-left: 0;
  }

  .sidebar-postlists li {
    display: inline-block;
    vertical-align: top;
    margin-right: -4px;
    width: 50%;
    padding: 0 15px;
  }
}

.btn-primary:focus,
.btn-primary.focus {
  border-color: transparent;
}

/* small mobile :320px. */
@media (max-width: 767px) {
  .container-fluid {
    width: 100%;
  }

  #company-page .expert-list {
    width: 100%;
  }

  .list-frbuttons li {
    width: 100% !important;
  }

  .modal-content {
    padding: 50px 0 0;
  }

  .modal-content .form-articleS {
    padding: 0;
  }

  .mlr-15 {
    margin-right: -15px;
    margin-left: -15px;
  }

  .modal-border {
    padding-right: 3px;
    padding-left: 3px;
  }

  .expert-list {
    width: 100%;
  }

  .expert-descr {
    margin-bottom: 10px;
  }

  .page-header h2 {
    font-size: 35px;
  }

  .page-header p {
    font-size: 18px;
  }

  .page-header {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .slider-section {
    top: 0;
  }

  .container {
    width: 100%;
  }

  nav li .parent-angle {
    display: none;
  }

  /* Slider */
  h1 {
    font-size: 30px;
  }

  .subtitle {
    font-size: 25px;
  }

  .info-box {
    margin-bottom: 35px;
  }

  .services-list div[class*='col-']:last-child .info-box {
    margin-bottom: 0;
  }

  .contents {
    text-align: center;
  }

  /* Services */
  .info-box-top {
    text-align: center;
  }

  .info-box-desc p {
    text-align: center;
  }

  /* blog */
  .post-img img {
    width: 100%;
  }

  .about-theme {
    text-align: left;
  }

  h3,
  .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
  }

  .mob-mt-50 {
    margin-top: 50px;
  }

  .about-img img {
    width: 100%;
  }

  .blog-sidebar {
    margin-top: 50px;
  }

  div[class*='col-']:nth-last-child(1) .info-box-style-5 {
    margin-bottom: 0;
  }

  .breadcrumb-item a,
  .breadcrumb-item.active {
    font-size: 12px;
    font-weight: normal;
  }

  .breadcrumb > li + li:before {
    font-size: 12px;
    font-weight: normal;
  }

  .search-btn {
    margin-bottom: 30px;
  }

  .section-title {
    text-align: center;
  }

  /* Button */
  .button-primary {
    font-size: 13px;
    line-height: 32px;
    padding: 0 10px;
  }

  .btn-green .fa,
  .btn-blue .fa,
  .btn-orange .fa {
    font-size: 19px;
    margin-right: 0;
    text-align: center;
    margin-top: 1px;
  }

  .btn-green,
  .btn-blue,
  .btn-orange {
    padding: 10px 12px 9px;
    margin-left: 10px;
  }

  .modal-dialog {
    margin: 10px auto;
  }

  .social-nav li a {
    margin: 0 10px;
  }

  .bk-search input {
    width: 292px;
  }

  .tab-content {
    padding: 15px;
  }

  .list-tabs {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
  }

  .sidebar-postlists li {
    width: 100%;
  }

  #comment-form .form-group {
    display: block;
    margin: 0 auto;
  }

  #comment-form .field-commentmodel-recaptcha {
    margin: 0 auto;
  }

  .green-theme .btn-green {
    margin: 0 auto;
    float: none;
  }

  #comment {
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .d-sm-none {
    display: none;
  }
}
@media (max-width: 768px) {
  .d-md-none {
    display: none;
  }
}

/* small mobile :320px. */
@media (max-width: 478px) {
  .db-mobile {
    display: block;
  }

  /* slider */
  h1 {
    font-size: 20px;
  }

  .subtitle {
    font-size: 17px;
  }

  .mh-text .h3 {
    font-size: 25px;
  }

  .expert-descr .expert-img,
  .expert-descr .expert-info {
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
  }

  .bk-search .btn-bradius {
    display: none;
  }

  .bk-search input {
    width: 270px;
  }

  .bk-search {
    margin-bottom: 0 !important;
  }

  .tab-content #article {
  }

  .tab-content {
    padding: 15px;
  }

  .tab-content .ncat {
    display: none;
  }

  .nav-tabs > li {
    float: none !important;
  }

  .tab-content .descr-news {
    display: block;
    text-align: center;
  }

  .tab-content .ndiscription .h5 {
    text-align: center;
  }

  .descr-news .author-rimg {
    width: 70%;
    margin-bottom: 10px;
  }

  .tab-content .descr-news p {
    width: 100%;
    padding-left: 0;
    text-align: left;
  }

  .list-tabs {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
  }

  #main-page .item .ncat {
    display: none;
  }

  #main-page .item {
    text-align: center;
  }

  #main-page .descr-news {
    display: block;
  }

  #main-page .descr-news p {
    width: 100%;
    padding-left: 0;
  }

  #comment {
    max-width: 230px;
  }

  #comment input,
  #comment textarea {
    width: 230px;
    max-width: 230px;
  }

  #submit-comment {
    font-size: 16px !important;
  }

  .field-commentmodel-recaptcha {
    overflow: hidden;
  }

  .ncat {
    display: none;
  }

  .rate-art div,
  .rate-art > span {
    float: none !important;
    text-align: left;
  }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container,
  .container-fluid {
    width: 450px;
  }
}

@media (max-width: 767px) {
  .consult-step {
    width: 100%;
    margin-bottom: 20px;
    flex-basis: 100% !important;
    &::before {
      display: none !important;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .consult-step {
    flex-basis: 135px !important;
  }
}
